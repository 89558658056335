<template>
  <div class="container">
    <Loading :spinner="this.spinner" />
    <div :class="spinner ? 'blurry' : ''">
      <ShowPath :path="this.path" />
      <div class="files" v-if="!folderEmpty">
        <ul v-for="(elem, i) in this.filesFormat" :key="i">
          <li
            class="row elem"
            :name="elem.name"
            :class="elem.is_dir ? 'directory' : 'file'"
            @click="elem.is_dir ? gotoDir(elem) : gotoFile(elem)"
          >
            <span class="elem--name col-md-3" :title="elem.name">
              {{ elem.name }}</span
            >
            <span class="elem--size col-md-3">{{ elem.size }}</span>
            <span class="elem--mod col-md-3">{{ elem.mtime }}</span>
          </li>
        </ul>
      </div>

      <div v-else class="folderEmpty">
        <p>
          Ce dossier est vide.<br /><br />
          <iframe
            src="https://giphy.com/embed/xT5LMBIW4I7671tqX6"
            width="480"
            height="366"
            frameBorder="0"
            class="folderEmptyGif"
            allowFullScreen
          ></iframe>
          <br />
          <a
            class="giflink"
            target="_blank"
            href="https://giphy.com/gifs/season-6-the-simpsons-6x18-xT5LMBIW4I7671tqX6"
            >via GIPHY</a
          >
        </p>
      </div>
    </div>
    <router-view :key="$router.fullPath" />
  </div>
</template>
<script>
import axios from "axios";
import ShowPath from "@/components/ShowPath.vue";
import Loading from "@/components/Loading.vue";
export default {
  name: "Repo",
  components: {
    ShowPath,
    Loading,
  },
  data() {
    return {
      path: { type: String, default: "/" },
      filesFormat: { type: Array, default: [] },
      folderEmpty: { type: Boolean, default: false },
      spinner: { type: Boolean, default: true },
      api: {
        dev: process.env.VUE_APP_API_DEV,
        prod: process.env.VUE_APP_API_PROD,
      },
    };
  },
  async created() {
    this.filesFormat = [];
    this.path = this.$route.fullPath;
    await this.buildList(this.path);
  },
  beforeUpdate() {
    this.path = this.$route.fullPath;
  },
  watch: {
    async path() {
      await this.buildList(this.path);
    },
  },

  methods: {
    gotoDir(elem) {
      if (!this.$router.getRoutes().some(({ name }) => name === elem.name)) {
        this.$router.addRoute({
          name: elem.name,
          path: `${this.path}/${elem.name}`,
          component: () => import("@/views/Repo.vue"),
        });
      }
      this.$router.push({ name: `${elem.name}` });
    },
    gotoFile(elem) {
      if (!this.$router.getRoutes().some(({ name }) => name === elem.name)) {
        this.$router.addRoute({
          name: elem.name,
          path: `${this.path}/${elem.name}`,
          component: () => import("@/components/ViewContent.vue"),
        });
      }
      this.$router.push({ name: `${elem.name}` });
    },
    async buildList(path) {
      this.spinner = true;
      this.folderEmpty = true;
      this.filesFormat = [];
      let files = [];
      await axios
        .get(process.env.VUE_APP_API_PROD + "/?fn=" + path)
        .then((response) => {
          if (response.data.length > 0) {
            response.data.forEach((d) => {
              //console.log(d);
              if (Object.prototype.hasOwnProperty.call(d, "err")) throw d;

              const dd = {
                name: d.name,
                size: this.formatFileSize(d.size),
                mtime: this.formatTimestamp(d.mtime),
                is_dir: d.is_dir,
                type: d.type,
              };

              files.push(dd);
            });
            this.sortList(files, "name");
            this.sortList(files, "is_dir");
            this.filesFormat = files;
            this.folderEmpty = false;
          }
          this.spinner = false;
        })
        .catch((err) => {
          this.spinner = false;
          this.$router.push({
            name: "Error",
            params: {
              0: this.path, //! <-- wtf?
              code: err.code,
              msg: err.msg,
              path: this.path,
            },
          });
        });
    },
    sortList(array, prop) {
      array
        .sort((a, b) => {
          if (a[prop] < b[prop]) {
            return -1;
          } else if (a[prop] > b[prop]) {
            return 1;
          } else {
            return 0;
          }
        })
        .reverse();
    },
    formatFileSize(bytes) {
      if (bytes === "-") {
        return "-";
      }
      let format = ["bytes", "KB", "MB", "GB"];
      for (var pos = 0; bytes >= 1000; pos++, bytes /= 1024);
      let d = Math.round(bytes * 10);
      return pos
        ? [parseInt(d / 10), ".", d % 10, " ", format[pos]].join("")
        : bytes + " bytes";
    },
    formatTimestamp(mtime) {
      const date = new Date(mtime * 1000);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      return [
        months[date.getMonth()],
        " ",
        date.getDate(),
        ", ",
        date.getFullYear(),
        " ",
        date.getHours() % 12 || 12,
        ":",
        (date.getMinutes() < 10 ? "0" : "") + date.getMinutes(),
        " ",
        date.getHours() >= 12 ? "pm" : "am",
      ].join("");
    },
  },
};
</script>
<style scoped>
.giflink {
  color: var(--main-front-blue);
  font-style: italic;
  text-align: right;
}
.folderEmpty {
  text-align: center;
}
.folderEmptyGif {
  border-radius: 15px;
}
/* TODO: */
@media only screen and (max-width: 750px) {
  .elem--size {
    display: none;
  }
  .elem--mod {
    display: none;
  }
}

.elem--name,
.elem--size,
.elem--mod {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

li {
  text-decoration: underline overline;
}
li > ul > li:last-child {
  border: none;
}

.elem:hover,
.elem--name:hover,
.elem--mod:hover,
.elem--size:hover {
  cursor: pointer;
  color: var(--main-front-color);
  text-decoration: underline overline var(--main-back-color);
  text-decoration-color: var(--main-front-color);
  text-decoration-thickness: 2px;
  transform: scale(1.02);
  font-weight: bold;
  white-space: nowrap;
  text-overflow: none;
  overflow: hidden;
}

/** ICONS */
.files >>> ul {
  list-style: none;
}

.files >>> ul li {
  width: 100%;
  height: 45px;
  position: relative;
}
.files >>> .directory,
.files >>> .file {
  position: relative;
}
.files >>> .directory::before {
  content: "";
  position: relative;
  height: 2em;
  width: 1.5em;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/img/folder.png");
}
.files >>> .file::before {
  content: "";
  height: 2em;
  width: 1.5em;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/img/file-code.png");
}
</style>
